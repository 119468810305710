<script setup>
import { ref, onMounted } from 'vue';
import { defineProps } from 'vue';

// Props
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  level: {
    type: Number,
    default: 0
  }
});

const relativeURL = ref('');

onMounted(() => {
  try {
    relativeURL.value = import.meta.env.BASE_URL || '';
  } catch (error) {
    console.error('Error fetching base URL:', error);
  }
});
</script>

<template>
  <v-list-item
    :to="item.getURL && item.type !== 'external' ? `${relativeURL}${item.to}` : item.to"
    :href="item.type === 'external' ? item.to : ''"
    rounded
    color="grey-ligthen-2"
    :disabled="item.disabled"
    :target="item.type === 'external' ? '_blank' : ''"
  >
    <!-- Icon Slot -->
    <template v-slot:prepend>
      <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
    </template>

    <v-list-item-title>{{ item.title }}</v-list-item-title>

    <!-- Sub Caption Slot -->
    <v-list-item-subtitle v-if="item.subCaption" class="text-caption mt-0 hide-menu">
      {{ item.subCaption }}
    </v-list-item-subtitle>

    <!-- Chip Slot -->
    <template v-slot:append v-if="item.chip">
      <v-chip :color="item.chipColor" class="sidebarchip hide-menu" size="small" :variant="item.chipVariant" :prepend-icon="item.chipIcon">
        {{ item.chip }}
      </v-chip>
    </template>
  </v-list-item>
</template>

<style></style>